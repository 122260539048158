/* stylelint-disable selector-class-pattern */
@mixin adf-about-theme($theme) {
  adf-about {
    /* custom ADF About Component Theme */

    background-color: var(--theme-about-panel-background-color);
    overflow: auto;

    .mat-accordion {
      box-shadow: none;
      border: none;

      &.adf-about-panel {
        .mat-expansion-panel {
          box-shadow: none;
          border-radius: 12px;
          margin: 24px;
          width: 95%;
          border: 1px solid var(--theme-about-panel-border-color);
        }
      }
    }

    .mat-expansion-panel-header {
      height: 80px;
      line-height: 32px;
    }

    mat-panel-title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
